:root {
    /* basic colors */
    --main-color: #000000;
    --main-secondary-color: #ffffff;
    --main-font-color: #000000;
    --main-bg-color: #ffffff;
    --main-error-color: #f04949;
    --main-card-bg-color: #F2F2F2;
    --main-shadow-basic-color: #000000;
    --main-footer-background-color: #333333;
    --main-footer-title-color: #EF4618;
    /* header colors */
    --header-nav-bg-color: var(--main-bg-color);
    --header-nav-item-color: var(--main-font-color);
    --header-bg-color: var(--main-bg-color);
    --header-nav-link-color: var(--main-color);
    --header-item-color: var(--main-color);
    --header-search-bg-color: var(--main-bg-color);
    --header-search-font-color: var(--main-font-color);
    --header-search-border-color: var(--main-color);
    /* mobile header colors */
    --mobile-header-bg-color: var(--main-color);
    --mobile-header-item-color: var(--main-secondary-color);
    --mobile-header-item-secondary-color: var(--main-color);
    --mobile-header-search-bg-color: var(--main-bg-color);
    --mobile-header-search-font-color: var(--main-font-color);
    --mobile-header-search-border-color: var(--main-secondary-color);
    --mobile-cart-item-color: var(--main-font-color);
    --mobile-cart-action-color: var(--main-color);
    /* footer colors */
    --footer-bg-color: var(--main-footer-background-color);
    --footer-line-color: #e0e6e8;
    --footer-item-color: #ffffff;
    --footer-title-color: var(--main-footer-title-color);
    /* card colors */
    --card-bg-color: var(--main-bg-color);
    --card-item-primary-color: var(--main-font-color);
    --card-item-secondary-color: var(--main-color);
    --card-action-primary-color: var(--main-color);
    --card-action-secondary-color: var(--main-secondary-color);
    --card-font-color: var(--main-font-color);
    /* modal card colors and childModal.vue component */
    --modal-card-bg-color: var(--main-bg-color);
    --modal-card-item-primary-color: var(--main-font-color);
    --modal-card-item-secondary-color: var(--main-secondary-color:);/*?:?*/
    --modal-card-action-primary-color: var(--main-color);
    --modal-card-action-secondary-color: var(--main-secondary-color);
    /* dropdown cards colors */
    --dropdown-card-bg-color: var(--main-bg-color);
    --dropdown-card-action-color: var(--main-color);
    --dropdown-card-item-color: var(--main-font-color);
    --dropdown-card-button-bg-color: var(--main-color);
    --dropdown-card-button-font-color: var(--main-secondary-color);
    /* sidebars colors */
    --sidebar-bg-color: var(--main-bg-color);
    --sidebar-item-color: var(--main-color);
    --sidebar-button-bg-color: var(--main-color);
    --sidebar-button-font-color: var(--main-secondary-color);
    /* slider colors */
    --slider-action-primary-color: var(--main-color);
    --slider-action-secondary-color: var(--main-font-color);
    /* special colors */
    --action-item-primary-color: var(--main-color);
    --action-item-secondary-color: var(--main-secondary-color);
    --input-font-color: var(--main-font-color);
    --search-font-color: var(--main-font-color);
    --block-title-color: var(--main-font-color);
    --checkbox-color: var(--main-color);
    /* shadows */
    --shadow: 0px 3px 5px 5px rgba(230, 230, 230, 0.456);
    --shadow-card: 10px 10px 0px 0px #33333366;
    --shadow-hover: 0px 10px 10px 0px rgba(230, 230, 230, 0.656);
    --slide-overlay: linear-gradient(
        to bottom,
        rgba(237, 237, 237, 0) 0%,
        var(--main-bg-color) 100%
    );
    --image-ellipsis: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, .2) 20%,
        rgba(255, 255, 255, .8) 60%,
        var(--main-bg-color) 100%
    );
    --image-bg-color: linear-gradient(
        315deg,
        color-mod(var(--main-bg-color) lightness(40%)) 0%,
        var(--main-bg-color) 74%
    );
    /* special variables */
    --breadcrumbs-main-color: var(--main-color);
    --breadcrumbs-active-color: var(--main-font-color);
    --breadcrumbs-divider: '/';
    /* skeleton placeholder */
    --placeholder-color: #d9d9d9;
    --placeholder-image: linear-gradient(315deg, #d9d9d9 0%, #f6f2f2 74%);
}
