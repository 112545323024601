@import '../../css/variables.css';
:root {
    /* все переменные вынесены в файл variables.css */
}

/* default sets */
html,
body {
    position: relative;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    background: #EBE6E6;
}

.wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.content {
    flex: 1 0 auto;
}

.footer {
    flex: 0 0 auto;
}

main {
    min-height: 500px;
    @media (max-width: 767px) {
        margin-top: 69px;
    }
}

button {
    background: none;
    border: none;
}

h1, h2, h3, h4, h5, h6, p {
    color: var(--main-font-color);
}

button, a {
    cursor: pointer;
}

.hide {
    display: none !important;
}

/* special container addon to flexboxgrid2 class */
@media only screen and (min-width: 1440px) {
    .container {
        width: calc(1440px - 16px);
        max-width: 100%;
    }
}

.body-hide {
    overflow: hidden !important;
    position: relative !important;
    padding-left: 0px;
    padding-top: 0px;
    padding-right: 0px;
    margin-left: 0;
    margin-top: 0;
    margin-right: 0px !important;
}

/* a11y class for screenreaders */
.show-for-sr {
    position: absolute !important;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    border: 0;
}

/* breadcrumbs */
.breadcrumbs {
    display: flex;
    flex-flow: row nowrap;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    font-size: 10px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.breadcrumbs__link {
    color: var(--breadcrumbs-main-color);
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}

.breadcrumbs__link_active {
    color: var(--breadcrumbs-active-color);
}

.breadcrumbs__divider {
    color: var(--breadcrumbs-active-color);
    &:before {
        content: var(--breadcrumbs-divider);
    }
}

/* custom checkbox */
.checkbox {
    display: none;
    & + label {
        width: 17px;
        height: 17px;
        border: 1px solid var(--checkbox-color);
        border-radius: 4px;
        background: inherit;
        margin-right: 6px;
        display: inline-block;
        position: relative;
    }
    & + label:active {
        background: color-mod(var(--checkbox-color) lightness(90%));
    }
    & + label:hover {
        background: color-mod(var(--checkbox-color) lightness(90%));
    }
    &:checked + label {
        background: color-mod(var(--checkbox-color) lightness(90%));
        border: 1px solid var(--checkbox-color);
        color: var(--checkbox-color);
    }
    &:checked + label:after {
        content: "✔";
        font-size: 13px;
        position: absolute;
        top: 1px;
        left: 2px;
        color: var(--checkbox-color);
    }
}

/* base */

.nav__upper {
    color: rgba(51, 51, 51, 0.7);
    font-weight: 400;
    line-height: 19px;
    font-size: 16px;
    @media (max-width: 768px) {
        display: none;
    }
}

.nav__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 87%;
    padding: 55px 0 0 0;
}

.nav__location-indication {
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-width: 305px;
    min-height: 40px;
    border: none;
    padding: 10px;
    outline: none;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    font-size: 16px;
    text-align: center;
    background: #EF4618;
    color: #F2F2F2;

    &:hover {
        background: #F7967D;
        cursor: pointer;
    }

    &:active {
        background: #9B2708;
    }

    &:disabled {
        background: #E0E0E0;
        color: #8B8B8B;
        cursor: not-allowed;
    }
}

.nav__block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: none;
    padding: 2px 0;
}

.nav__block--justify-to-start {
    justify-content: flex-start;
}

.nav__block--justify-to-start .nav__link {
    margin: 0 36px 0 0;
}

.nav__link {
    text-decoration: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
}

.nav__link:hover,
.nav__link:active {
    text-decoration: underline;
}

.nav__link__category {
    text-decoration: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
    margin-right: 20px;
}

.nav__locale,
.nav__schedule{
    text-decoration: none;
    color: color-mod(var(--header-nav-item-color) lightness(40%));
}

.nav__locale-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 1100px;
    transform: translate(-50%, -50%);
    background: white;
    z-index: 40;
    visibility: hidden;
    opacity: 0;
    transition: 1s;
    @media (max-width: 1100px) {
        width: 85%;
    }
}

.nav__locale-modal-auth {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 1100px;
    transform: translate(-50%, -50%);
    background: white;
    z-index: 40;
    visibility: hidden;
    opacity: 0;
    transition: 1s;
    border-radius: 15px;
    @media (max-width: 1100px) {
        width: 85%;
    }
}

.nav__locale-modal__visible {
    visibility: visible;
    opacity: 1;
}

.nav__locale-close {
    position: fixed;
    top: 45px;
    left: 87%;
    color: white;
    visibility: hidden;
    opacity: 0;
    transition: 1s;
    font-size: 40px;
    cursor: pointer;
    z-index: 40;
}

.nav__locale-close__visible {
    visibility: visible;
    opacity: 1;
}

.nav__locale-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(51, 51, 51, 0.5);
    z-index: 39;
    visibility: hidden;
    opacity: 0;
    transition: 1s;
}

.nav__locale-background__visible {
    visibility: visible;
    opacity: 1;
}

.nav__schedule span{
    margin:0 5px;
}

.nav__phone a {
    text-decoration: none;
    color: color-mod(var(--header-nav-item-color) lightness(40%));
}

.nav__profile {
    display: flex;
    align-items: end;
    justify-content: space-between;
}

.nav__profile-points {
    text-decoration: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
    margin-right: 31px;
}

.nav__profile-button{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

.nav__profile-button a{
    text-decoration: none;
    color: #333333;
}

.nav__profile button{
    display: flex;
    align-items: center;
    min-width: 172px;
    min-height: 40px;
    font-weight: 500;
    line-height: 19px;
    font-size: 16px;
    text-align: center;
    background: none;
    color: #333333;

    &:hover {
        color: #F7967D;
        cursor: pointer;
    }

    &:active {
        color: #9B2708;
    }

    &:disabled {
        color: #8B8B8B;
        cursor: not-allowed;
    }
}

.header {
    width: 100%;
    z-index: 1;
    background: #EBE6E6;
    box-shadow: var(--shadow);
    @media (max-width: 768px) {
        padding: 12px 0;
        background: var(--mobile-header-bg-color);
    }
}

.nav {
    display: flex;
    flex-flow: column nowrap;
    font-weight: normal;
    line-height: 26px;
    font-size: 16px;
    letter-spacing: 0.5px;
    @media (max-width: 768px) {
        display: none;
    }
}

.nav__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 0 0 20px 0;
}

.nav__company {
    display: flex;
    align-items: start;
    flex-direction: column;
}

.nav__company-logo {
    text-decoration: none;
}

.nav__company-logo-img {
    height: 34px;
}

.nav__company-name {
    text-decoration: none;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    color: #333333;
}


.nav__search-wrapper {
    display: flex;
    flex-grow: 0.8;
    width: 35px;
    position: absolute;
    right: 170px;
    transition: width .5s ease;
    & form {
        width: 100%;
    }
    &:hover,
    &:focus-within{
        width: 300px;
        transition: width .5s ease;
        & .nav__search{
            & input{
                width:90%;
                transition: width .5s ease;
            }
        }
    }
}

.nav__search-wrapper--no-basket{
    right: 25px;
}

.nav__search {
    position: relative;
    box-sizing: border-box;
    border-radius: 200px;
    padding: 2px 7px;
    background: none;
    color: #333333;
}

.nav__search input {
    border: none;
    outline: none;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: #333333;
    background: none;
    width: 1px;
    transition: width .5s ease;
}

.nav__search input::placeholder {
    color: color-mod(var(--header-search-font-color) lightness(60%));
    border-bottom: 1px solid color-mod(var(--header-search-font-color) lightness(60%));
}

.nav__search label {
    position: absolute;
    top: -10px;
    padding: 0 2px 0 2px;
    font-style: normal;
    font-weight: normal;
    line-height: 16px;
    font-size: 12px;
    letter-spacing: 0.4px;
    text-transform: capitalize;
    color: var(--header-item-color);
    background: var(--header-bg-color);
}

.nav__search button {
    position: absolute;
    right: 7px;
    top: 50%;
    border: none;
    background: none;
    color: var(--header-search-font-color);
    opacity: 0.6;
    padding: 0;
    transform: translateY(-50%);

    &:before {
        font-size: 19px;
    }
}

.nav__order-status {
    text-decoration: none;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    background: none;
    color: var(--header-item-color);
    border-radius: 50%;
}

.nav__order-status.mdi:before {
    font-size: 40px;
}

.nav__order-status:hover {
    color: color-mod(var(--header-item-color) blackness(+10%));
}

.nav__cart {
    position: relative;
    text-decoration: none;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-width: 152px;
    border: none;
    padding: 10px 35px;
    outline: none;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    font-size: 16px;
    text-align: center;
    background: #EF4618;
    color: #F2F2F2
}

.dropmic--show .nav__cart {
    background: #9B2708;
}

.nav__cart:hover {
    background: #F7967D;
    cursor: pointer;
}

.nav__cart:active {
    background: #9B2708;
}

.nav__cart:disabled {
    background: #E0E0E0;
    color: #8B8B8B;
}

.nav__cart span {
    &:last-child::before {
        content: '|';
        position: absolute;
        transform: translateX(-12px);
    }
}

/* стили дропдауна */

.nav__cart-dropdown {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    z-index: 25;
    border: none;
    border-radius: 4px;
    background: #F2F2F2;
    /* overflow-y: scroll; */
    position: fixed;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    width: 400px;
    max-width: none;
    max-height: none;
    transform: none !important;
}

.nav__cart-dropdown-background {
    position: fixed;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    max-width: none;
    width: 100%;
    max-height: none;
    transform: none !important;
    background: rgba(51, 51, 51, 0.5);
    z-index: 24;
}

.nav__cart-dropdown-close {
    position: absolute;
    right: 400px;
    top: 20px;
}

.nav__cart-dropdown-top {
    display: flex;
    align-items: end;
    padding: 10px 20px;
    width: 100%;
    height: 70px;
    background: #EBE6E6;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #333333;
}

.nav__cart-dropdown-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding-bottom: 50px;
}

.nav__cart-dropdown-list {
    position: relative;
    overflow-y: auto;
    padding: 20px;
    padding-bottom:10px;
    width: 100%;
}

.nav__cart-dropdown-total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    padding: 20px;
}

.nav__cart-dropdown-promocode, .nav__cart-dropdown-bonus, .nav__cart-dropdown-costing, .nav__cart-dropdown-delivery {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
    padding-bottom: 10px;
    width: 100%;
}

.cart-dropdown-promocode__button, .cart-dropdown-bonus__button {
    width: 24px;
    height: 24px;
    border: 1px solid rgba(51, 51, 51, 0.5);
    border-radius: 50%;
}

.nav__cart-dropdown-sum {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
    padding: 30px 0;
    width: 100%;
}

/* модалка промокода */
.promocode-modal, .paid-by-bonuses-modal {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: none;
}

.promocode-modal__background, .paid-by-bonuses-modal__background {
    position: relative;
    height: 100%;
    background: rgba(51, 51, 51, 0.5);
    display: none;
}

.promocode-modal__main, .paid-by-bonuses-modal__main  {
    position: absolute;
    bottom: -268px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    height: 0;
    background: #F2F2F2;
    border-radius: 15px 15px 0px 0px;
    padding: 0;
    transition: .5s;
    overflow: hidden;
}

.promocode-modal__header, .paid-by-bonuses-modal__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.promocode-modal__header-title, .paid-by-bonuses-modal__header-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
}

.promocode-modal__input, .paid-by-bonuses-modal__input {
    border: none;
    background: none;
    width: 100%;
    border-bottom: 1px solid rgba(51, 51, 51, 0.2);
    padding: 10px 0;

    &:focus {
        border: none;
        outline: none;
        border-bottom: 1px solid #EF4618;
    }
}

.promocode-modal__button, .paid-by-bonuses-modal__button {
    text-decoration: none;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: none;
    font-weight: 500;
    line-height: 19px;
    font-size: 16px;
    text-align: center;
    background: #EF4618;
    color: #F2F2F2;
    width: 305px;
    height: 50px;

    &:hover {
        background: #F7967D;
        cursor: pointer;
    }

    &:active {
        background: #9B2708;
    }
}

.paid-by-bonuses-modal__description {
    align-self: flex-start;
    color: #33333380;
    font-size: 14px;
}

/* это хак, починить стили дропдаун плагина */
.dropmic--show::after,
.dropmic--show::before {
    display: none;
}

.dropdown-card {
    display: flex;
    justify-content: space-between;
    padding: 10px 0 10px 0;
}

.dropdown-card__left {
    padding-right: 20px;
}

.dropdown-card__right {
    display: flex;
    align-items: start;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
}

.dropdown-card:first-child {
    padding: 0 0 10px 0;
}

.dropdown-card:last-child {
    border-bottom: none;
}

.dropdown-card__header {
    display: flex;
    align-items: start;
    justify-content: space-between;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
    text-transform: none;
    padding: 0 0 10px 0;
}

.dropdown-card__img--size {
    width: 88px;
    height: 88px;
}

.dropdown-card__img--none {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    background: none;
    color: var(--card-font-color);
    border-radius: 5px;
}

.dropdown-card__img--none::before {
    content: '\f82a';
    color: lightgray;
    padding: 0 5px;
    display: inline-block;
    font: normal normal normal 12px/1 "Material Design Icons";
    font-size: 35px;
    text-rendering: auto;
    line-height: 24px;
}

.dropdown-card__dismiss {
    color: #333333;
    cursor: pointer;
}

.dropdown-card__description {
    display: flex;
    align-items: start;
    justify-content: space-between;
    flex-direction: column;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: rgba(51, 51, 51, 0.5);
    padding-bottom: 20px;
}

.dropdown-card__description-title {
    padding-bottom: 8px;
}

.dropdown-card__button {
    text-decoration: none;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: none;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    font-size: 16px;
    text-align: center;
    background: #EF4618;
    color: #F2F2F2;
    padding: 16px 58px;

    &:hover {
        background: #F7967D;
        cursor: pointer;
    }

    &:active {
        background: #9B2708;
    }

    &:disabled {
        background: #E0E0E0;
        color: #8B8B8B;
        cursor: not-allowed
    }
}

.dropdown-card__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
}

.dropdown-card__info-block {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
}

.info-title {
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    font-size: 10px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    padding: 0 0 5px 0;
}

.info-quantity {
    min-width: 20px;
    width: 88px;
    height: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(51, 51, 51, 0.5);
    border-radius: 35px;
}

.info-quantity button {
    background: none;
    border: none;
    outline: none;
    color: #333333;
}

.info-quantity button:hover {
    color: var(--dropdown-card-item-color);
}

.info-quantity__count {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
}

.nav__cart-dropdown-button {
    text-decoration: none;
    display: inline-block;
    border: none;
    border-radius: 200px;
    padding: 6px 16px 6px 16px;
    margin: 0;
    outline: none;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    background: var(--dropdown-card-button-bg-color);
    color: var(--dropdown-card-button-font-color);
}

.nav__cart-dropdown-button:hover {
    background: color-mod(var(--dropdown-card-button-bg-color) blackness(+10%));
}

.nav__cart-dropdown_empty {
    display: flex;
    justify-content: center;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    font-size: 14px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}
/* конец стилей дропдауна */

.nav-mobile {
    display: none;
}

@media (max-width: 768px) {
    .header{
        position: relative;
        z-index: 50;
        margin: 0;
        padding: 0;
    }

    .header .container {
        width: 100%;
        max-width: 100%;
        margin: 0;
        padding: 0;
    }

    .nav-mobile {
        position: fixed;
        width: calc(100% - 24px);
        height: 69px;
        padding: 0 12px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        background-color: var(--mobile-header-bg-color);
        top: 0;
    }

    .nav-mobile__sidebar-button {
        background: none;
        border: none;
        color: var(--mobile-header-item-color);
        outline: none;
        margin-left: 15px;

        @media (max-width: 575px) {
            margin-left: 65px;
        }
    }

    .nav-mobile__sidebar-button:hover {
        outline: 2px solid var(--mobile-header-item-color);
        outline-offset: 1px;
    }

    [sidebarjs-container] {
        width: 100%;
        max-width: 100%;
    }
    .nav-mobile__sidebar {
        background-color: var(--main-bg-color);
        color: var(--sidebar-item-color);
        display: flex;
        flex-flow: column nowrap;
        overflow: hidden;
        overflow-y: scroll;
        height: 100%;
    }

    .nav-mobile__sidebar-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        height: 70px;
        background: var(--main-bg-color);
    }

    .nav-mobile__sidebar-item span,
    .nav-mobile__sidebar-item a {
        text-decoration: none;
        width: 100%;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        color: var(--main-footer-background-color);
    }

    .nav-mobile__sidebar-item span {
        width: auto;
    }

    .nav-mobile__sidebar-item span.nav-mobile__sidebar-item-subscript {
        margin-top: 5px;
        font-size: 12px;
        font-weight: 300;
    }

    .nav-mobile__sidebar-item a.nav-mobile__sidebar-item-company-name {
        font-size: 32px;
        line-height: 1.2;
        font-weight: 700;
    }

    .nav__sidebar-item a:hover {
        outline: 2px solid inherit;
        outline-offset: 2px;
    }

    .nav-mobile__sidebar-item-separator ~ .nav-mobile__sidebar-item {
        padding-left: 25px;
    }

    .nav-mobile__sidebar-item-separator {
        margin: 0 12px;
        width: calc(100% - 24px);
        height: 1px;
        background-color: #989898;
    }

    .sidebar-item__company-name {
        padding-bottom: 0;
    }

    .sidebar-item__points {
        display: flex;
        justify-content: space-between;
    }

    .sidebar-item__phone {
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 0;
    }

    .nav-mobile__sidebar-close {
        color: var(--main-error-color);
        font-size: 24px;
    }

    .nav-mobile__sidebar-actions {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-around;
    }

    .nav-mobile__company {
        display: flex;
    }

    .nav-mobile__logo {
        text-decoration: none;
    }

    .nav-mobile__logo-img {
        height: 34px;
    }

    .nav-mobile__link {
        text-decoration: none;
        color: var(--mobile-header-item-color);
        font-size: 32px;
        line-height: 1.2;
        font-weight: 700;
    }

    .nav-mobile__search-wrapper {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        border: 2px solid var(--mobile-header-search-border-color);
        box-sizing: border-box;
        border-radius: 200px;
        padding: 4px 16px;
        background: var(--mobile-header-search-bg-color);
        color: var(--mobile-header-search-font-color);
    }

    .nav-mobile__search-wrapper input {
        border: none;
        outline: none;
        font-style: normal;
        font-weight: normal;
        line-height: 20px;
        font-size: 14px;
        letter-spacing: 0.25px;
        width: 100%;
    }

    .nav-mobile__search-wrapper input::placeholder {
        color: color-mod(var(--mobile-header-search-font-color) lightness(60%));
    }

    .nav-mobile__search-wrapper label {
        position: absolute;
        top: -10px;
        padding: 0 2px 0 2px;
        font-style: normal;
        font-weight: normal;
        line-height: 16px;
        font-size: 12px;
        letter-spacing: 0.4px;
        text-transform: capitalize;
        color: var(--mobile-header-item-color);
        background: var(--mobile-header-bg-color);
    }

    .nav-mobile__search-wrapper button {
        border: none;
        background: none;
        color: var(--mobile-header-search-font-color);
        opacity: 0.6;
    }

    .nav-mobile__search-form-md {
        flex-grow: 0.6;
        @media (max-width: 575px) {
            display: none;
        }
    }

    .nav-mobile__search-form-xs {
        width: 34px;
        height: 32px;
        background: var(--mobile-header-bg-color);
        border-radius: 50%;
        margin-left: auto;

        @media (min-width: 576px) {
            display: none;
        }
    }

    .nav-mobile__search-button-xs {
        display: inline-block;
        border: none;
        padding: 8px;
        padding-bottom: 0;
        outline: none;
        line-height: 14px;
        text-align: center;
        background: var(--mobile-header-bg-color);
        color: var(--mobile-header-item-color);
        &:hover {
            cursor: pointer;
            /*background: color-mod(var(--mobile-header-bg-color) blackness(+10%));*/
        }
    }

    .nav-mobile__search-panel-xs {
        padding: 12px;
        min-width: 250px;
        border: none;
        box-shadow: var(--shadow);
        & .nav-mobile__search-wrapper {
            border: 2px solid var(--header-item-color);
            background: var(--header-search-bg-color);
            color: var(--header-search-font-color);
        }
    }

    .nav-mobile__footer{
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        height: 64px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 12px;
        background: transparent;
    }

    .nav-mobile__cart {
        position: relative;
        text-decoration: none;
        border-radius: 200px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        min-width: 100px;
        border: none;
        padding: 8px 16px 8px 16px;
        outline: none;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        font-size: 14px;
        text-align: center;
        letter-spacing: 0.75px;
        text-transform: uppercase;
        background: var(--main-footer-title-color); /*var(--mobile-header-item-color);*/
        color: var(--main-bg-color);
        @media (max-width: 424px) {
            justify-content: space-between;
            min-width: 50px;
            min-height: 18px;
        }
    }

    .nav-mobile__cart:hover {
        background: color-mod(var(--mobile-header-item-color) blackness(+10%));
    }
}

.nav-mobile__cart-sum {
    font-size: 18px;
}

.nav-mobile__cart-list {
    display: flex;
    flex-flow: column nowrap;
    padding: 8px 20px 0 20px;
    background: var(--main-bg-color);
    overflow-y: scroll;
    height: 75%;
}

.mobile-cart-item {
    display: flex;
    align-items: start;
    justify-content: space-between;
    padding: 0 0 8px 0;
    color: var(--mobile-cart-item-color);
}

.mobile-cart-item:not(:first-child) {
    padding: 8px 0;
}

.mobile-cart-item img {
    width: 100%;
}

.mobile-cart-item__left {
    width: 30%;
}

.mobile-cart-item__right {
    width: 65%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

.mobile-cart-item__header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
}

.mobile-cart-item__title {
    display: inline-block;
    color: var(--main-footer-background-color);
}

.mobile-cart-item__delete {
    color: var(--main-footer-background-color);
    cursor: pointer;
}

.mobile-cart-item__description {
    margin: 8px 0;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: rgba(51, 51, 51, 0.5);
}

.mobile-cart-item__options-block {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 8px 0;
    width: 100%;
}

.mobile-cart-item__option {
    display: flex;
    flex-flow: column nowrap;
}

.mobile-cart-item__option-title {
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    font-size: 10px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: inherit;
}

.mobile-cart-item__option-price {
    padding: 4px 0;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    font-size: 14px;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    color: inherit;
}

.mobile-cart-item__option-quantity {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 20px;
    width: 88px;
    height: 29px;
    border: 1px solid rgba(51, 51, 51, 0.5);
    border-radius: 35px;
    color: var(--main-footer-background-color);
    & button {
        background: none;
        color: var(--main-footer-title-color);
    }
}

.option-quantity__count {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: var(--main-footer-background-color);
}

.mobile-cart__button {
    position: sticky;
    bottom: 0;
    text-decoration: none;
    display: inline-block;
    border: none;
    border-radius: 25px;
    outline: none;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    background: var(--main-footer-title-color);
    color: var(--main-bg-color);
    padding: 15px 0;
}

.mobile-cart__button:hover {
    background: color-mod(var(--sidebar-button-bg-color) blackness(+10%));
}

.nav-mobile__cart-empty {
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    font-size: 14px;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    color: inherit;
}
/* footer */

.footer {
    background: var(--footer-bg-color);
    padding: 45px 0 0 0;
}

.footer__content {
    border-bottom: 1px solid var(--footer-line-color);
    padding: 0 0 24px 0;
}

.links_container {
    @media (max-width: 748px) {
        text-align: center;
    }
}


.links {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin: 0 2px;
    
    @media (max-width: 768px) {
        align-items: center;
        margin: 0 10px;
    }
}

.links a {
    padding: 0 0 12px;
}

.links__button_group {
   display: inline-grid;
}

.links__title {
    font-weight: 500;
    line-height: normal;
    font-size: 18px;
    letter-spacing: 0.15px;
    text-transform: uppercase;
    color: var(--footer-title-color);
}

.links__item {
    text-decoration: none;
    font-weight: normal;
    line-height: 20px;
    font-size: 14px;
    letter-spacing: 0.25px;
    opacity: 0.6;
    text-transform: uppercase;
    color: var(--footer-item-color);
}

.links__button {
    border-radius: 5px;
    border: white 1px groove;
    width: 178px;
    margin-bottom: 20px;
    color: white;
}
.links__button__container {
    display: flex;
    padding: 4px;
    .button-tittle__apple {
        font-size: 13px;
    }
    .text__apple {
        font-size: 24px;
    }
    .button-tittle__google_play {
        font-size: 11px;
        text-align: left;
    }
    .text__google_play {
        font-size: 20px;
    }
}
.links__button__content {
    margin-left: 3px;
    line-height: 18px;
}

.links__item:hover {
    opacity: 0.95;
}

.margin-top-10 {
    margin-top: 10px;
}

.copyright {
    padding: 24px 32px 8px 32px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    @media (max-width: 320px) {
        padding: 16px 8px;
    }
}

.copyright__date {
    font-style: normal;
    font-weight: normal;
    line-height: 16px;
    font-size: 12px;
    opacity: 0.6;
    color: var(--footer-item-color);
}

.copyright__link {
    text-decoration: none;
    font-style: normal;
    font-weight: normal;
    line-height: 16px;
    font-size: 12px;
    color: var(--footer-item-color);
    opacity: 0.6;
}

.copyright__link:hover {
    opacity: 0.95;
}

/* footer (mobile) */

@media (max-width: 390px) {
    .footer {
        padding: 20px 0;
    }

    .footer .links__title {
        padding: 0;
    }

    .footer .container {
        margin: 0 12px;
        padding: 0;
    }

    .footer .container .row.around-xs {
        flex-direction: column;
        margin: 0;
    }

    .footer .row.around-xs .links {
        align-items: flex-start;
        margin: 0;
    }

    .footer .row.around-xs .links__button_group {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .footer .row.around-xs .links__title {
        text-align: left;
    }
}

/* pages */

/* auth */
.auth {
    padding: 55px 20px 55px 20px;
}

.auth-container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.auth-card {
    width: 100%;
    display: flex;
    background: #FFFFFF;
    border-radius: 15px;
    justify-content: center;
}

.auth-card__block_title {
    font-size: 32px;
    font-weight: 500;
    margin-top: 40px;
    @media (max-width: 600px) {
        margin-top: 0;
    }
}

.auth-card__block {
    @media (max-width: 550px) {
        width: 100%;
    }
}

.auth-card__block:first-child {
    @media (max-width: 1028px) {
        display: none;
    }
}

.auth-card__block_input {
    margin-top: 40px;
    @media (max-width: 550px) {
        text-align: center;
    }
}

.auth_card__block_input_tittle {
    width: 300px;
    font-size: 16px;
    color: #33333380;
    margin-bottom: 10px;
}

.auth-card__block_input_repeat_code {
    font-size: 13px;
    margin-top: -10px;
    cursor: pointer;
}

.auth-card__block_input_change_phone {
    font-size: 13px;
    margin-top: 0;
    cursor: pointer;
}

.auth-card__block_input_change_phone:hover, .auth-card__block_input_repeat_code:hover {
    color: var(--footer-title-color)
}


.auth-card__block_personal_data {
    word-wrap: break-word;
    max-width: 300px;
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
    line-height: 14.52px;
    font-weight: 400;
    color: #33333380;
    @media (max-width: 550px) {
        max-width: none;
    }
}

/* about */
.about {
    padding: 20px 20px 55px 20px;
}

.about__content {
    color: var(--block-title-color);
    padding: 20px;
    margin: 0 auto;
    max-width: 750px;
    text-align: justify;
    border-radius: 4px;
    box-sizing: border-box;
    background: var(--card-bg-color);
    color: var(--card-item-primary-color);
    box-shadow: var(--shadow);
}

.contacts {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    padding: 20px;
    margin: 0 auto;
    max-width: 750px;
    background: var(--card-bg-color);
    color: var(--card-item-primary-color);
    box-shadow: var(--shadow);
    border-radius: 4px;
    box-sizing: border-box;
}

.contacts__title {
    margin-top: 0;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-size: 20px;
    letter-spacing: 0.15px;
}

.contacts__item {
    display: flex;
    flex-flow: row nowrap;
    text-decoration: none;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    font-size: 14px;
    letter-spacing: 1.5px;
}

.contacts__item span {
    padding: 0 8px 0 0;
}

.contacts__item a {
    text-decoration: none;
    opacity: 0.87;
    color: var(--action-item-primary-color);
}

.contacts__item a:hover {
    opacity: 1;
}

.feedback {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding: 20px 0 0 0;
    margin: 0 auto;
    max-width: 750px;
}

.feedback__button {
    display: inline-block;
    border: none;
    border-radius: 200px;
    padding: 8px 16px 8px 16px;
    outline: none;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    font-size: 20px;
    text-align: center;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    background: var(--card-action-primary-color);
    color: var(--card-action-secondary-color);
    margin: 0 0 20px 0;
}

.yandex__container {
    max-width: 750px;
    margin: 30px auto;
}

.yandex__map {
    width: 100%;
    display: inline-block;
    position: relative;
}

.yandex__map--dummy {
    margin-top: 50%;
}

.yandex__map--api {
    position: absolute;
    top: 0;left: 0;right: 0;bottom: 0;
    box-sizing: border-box;
    box-shadow: var(--shadow);
    background-color: var(--placeholder-color);
    background-image: var(--placeholder-image);
    box-sizing: border-box;
    border-radius: 4px;
}

/* article and articles */

.article {
    padding: 20px;
}

.articles {
    padding: 20px;
}

.articles__title {
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 34px;
    letter-spacing: 0.25px;
    color: var(--block-title-color);
}

.articles__description {
    position: relative;
    transition: box-shadow 0.25s;
    display: flex;
    flex-flow: row nowrap;
    border-radius: 15px;
    padding: 45px 30px 45px 30px;
    margin: 0 0 24px 0;
    background: var(--main-card-bg-color);
    color: var(--card-item-primary-color);
    @media (max-width: 767px) {
        flex-flow: column nowrap;
    }
}

.articles__content * {
    box-sizing: border-box;
}

.articles__content {
    display: grid;
    grid-template-columns: minmax(350px, 413px) minmax(350px, 413px) minmax(350px, 413px);
    grid-gap: 30px 20px;

    @media (max-width: 1228px) {
        grid-template-columns: minmax(350px, 413px) minmax(350px, 413px);
    }

    @media (max-width: 767px) {
        grid-template-columns: minmax(350px, 413px);
    }
}

.article-card {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    padding: 57px 30px 69px 30px;
    background-color: var(--main-card-bg-color);
    color: var(--card-item-primary-color);
    height: 305px;
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;

    @media (max-width: 767px) {

    }
}

.article-card--page {
    box-shadow: none;
}

.article-card__img {
    & img {
        border-radius: 2px;
        height: 300px;
    }

    @media (max-width: 425px) {
        & img {
            height: 280px;
        }
    }
    @media (max-width: 374px) {
        & img {
            height: 225px;
        }
    }
}

.article-card__content-preview {
    max-width: fit-content;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: #F1F1F1; /*var(--card-item-primary-color);*/
}

.article-card__title {
    text-decoration: none;
    min-height: 60px;
    font-size: 24px;
    line-height: 29px;
    font-style: normal;
    font-weight: 700;
    padding-bottom: 5px;
    letter-spacing: 0.15px;

    & h4 {
        font-size: 22px;
        margin-top: 0;
        margin-bottom: 5px;
        color: #F1F1F1; /*var(--card-item-primary-color);*/
    }

    & small {
        padding: 0 0 0 8px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        font-size: 14px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        opacity: 0.6;
    }

    a {
        text-decoration: none;
    }

    &:hover {
        color: var(--card-action-primary-color);
        & small {
            color: var(--card-item-primary-color);
        }
    }
}

.article-card__body {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 70%;
    height: 80px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    margin-top: -10px;
    
    p {
        color: #F1F1F1;
        margin: 0;
        margin-top: 5px;
    }

}

.article-card__show-more-button {
    display: inline-block;
    border-radius: 200px;
    padding: 13px 16px;
    outline: none;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    font-size: 16px;
    width: 150px;
    height: 45px;
    text-align: center;
    letter-spacing: 0.75px;
    text-decoration: none;
    background: var(--main-footer-title-color);
    color: var(--card-action-secondary-color);
    border: 1px solid var(--main-footer-title-color);

    &:hover {
        cursor: pointer;
        background-color: var(--card-action-primary-color);
        color: var(--main-font-color);
        /*background: color-mod(var(--card-action-primary-color) blackness(+10%));*/
    }

    @media (max-width: 420px) {
        width: 120px;
        height: 33px;
        padding: 8px 14px;
        line-height: 16px;
        font-size: 14px;
    }
}

/* cart */
.cart {
    position: relative;
    min-height: inherit;
    padding: 20px;
    @media (max-width: 425px) {
        padding: 20px 0 20px 0;
    }
}

.cart__button {
    display: inline-block;
    border: none;
    border-radius: 200px;
    padding: 8px 16px;
    outline: none;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    text-decoration: none;
    background: var(--card-action-primary-color);
    color: var(--card-action-secondary-color);
    &:hover {
        cursor: pointer;
        background: color-mod(var(--action-item-primary-color) blackness(+10%));
    }
}

.cart__button_disabled {
    opacity: 0.6;
}

.cart__button_disabled:hover {
    cursor: default;
    opacity: 0.6;
    background: var(--action-item-primary-color);
}

.cart__order-loader-wrapper {
    position: absolute;
    z-index: 150;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.order-loader {
    background: var(--card-bg-color);
    color: var(--card-item-primary-color);
    box-shadow: var(--shadow);
    border-radius: 4px;
    padding: 24px;
    display: flex;
    flex-flow: column nowrap;
    color: var(--card-item-primary-color);
    @media (max-width: 500px) {
        padding: 12px;
    }

    & h2 {
        @media (max-width: 350px) {
            font-size: 18px;
        }
    }
}

.order-loader__in-progress {
    color: color-mod(var(--card-item-primary-color) lightness(70%));
}

.order__number {
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    font-size: 14px;
    letter-spacing: 1.5px;

    & small {
        color: color-mod(var(--card-item-primary-color) lightness(50%));
        line-height: 22px;
        font-size: 16px;
        letter-spacing: 2.5px;
    }
}

.cart-content-wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    @media (max-width: 768px) {
        justify-content: space-around;
    }
}

.cart__empty {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

.cart__empty-label {
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    font-size: 18px;
    letter-spacing: 1.5px;
}

.cart__products {
    padding: 8px;
    display: flex;
    flex-flow: column nowrap;
    min-width: 300px;
    flex-shrink: 1;
    @media (max-width: 400px) {
        padding: 4px;
    }
}

.cart__products-title {
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 0;
    letter-spacing: 0.15px;
    color: var(--block-title-color);
}

.cart__nav {
    width: 400px;
    padding-left: 70px;
    flex-grow: 0;

    @media (max-width: 1300px) {
        display: none;
    }
}

.font-size-24 {
    font-size: 24px;
}

.font-size-32 {
    font-size: 32px;
}

.cart__delivery-price{
    font-style: normal;
    font-weight: 200;
    line-height: 12px;
    font-size: 16px;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    color: var(--header-item-color);
}

.cart__sum {
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    font-size: 22px;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    color: var(--header-item-color);
}

.cart__product-card {
    background: var(--card-bg-color);
    color: var(--card-item-primary-color);
    box-shadow: var(--shadow);
    border-radius: 4px;
    display: flex;
    flex-flow: column nowrap;
    padding: 8px;
    margin: 8px 0;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.cart__clear-cart-button {
    display: inline-block;
    border: none;
    border-radius: 200px;
    margin: 8px 0;
    padding: 8px 16px 8px 16px;
    outline: none;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    text-decoration: none;
    background: var(--main-error-color);
    color: var(--card-action-secondary-color);
    &:hover {
        cursor: pointer;
        background: color-mod(var(--main-error-color) blackness(+10%));
    }
}

.cart__map_container {
    height: 580px;
    width: 100%;
    iframe {
        border: none
    }
    @media (max-width: 1100px) {
        height: 400px;
    }
}

.cart__client-info {
    display: flex;
    flex-flow: column nowrap;
    flex-shrink: 2;
    & form {
        min-width: 0;
    }
}

.cart__content-wrapper {
    display: flex;
    justify-content: space-between;
}

.cart__delivery-info {
    padding: 4px;
    display: flex;
    flex-flow: column nowrap;
    flex: 1;
}

.cart__delivery-full-street {
    display: flex;
}

.cart__delivery-full-street-input {
    flex: 1
}

.cart__pickup-full-street-select {
    flex: 1
}

.cart__pickup-full-street-button {
    margin-top: 8px;
    margin-left: 10px;
}

.cart__delivery-full-street-button {
    margin-top: 8px;
    margin-left: 10px;
}

.delivery-type-preview {
    padding: 10px 20px 20px 20px;
}

.delivery {
    background: var(--card-bg-color);
    color: var(--card-item-primary-color);
    box-shadow: var(--shadow);
    padding: 50px 85px 50px 85px;
    border-radius: 15px;
    display: flex;
    flex-flow: column nowrap;
    @media (min-width: 990px) {
        min-width: 550px;
    }
    @media (max-width: 990px) {
        padding: 30px 20px 30px 20px;
    }
    @media (max-width: 350px) {
        padding: 30px 10px 30px 10px;
    }
}

.delivery__options {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
}

.delivery-option-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 200px;
    margin: 8px;
    padding: 8px 16px 8px 16px;
    outline: none;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    text-decoration: none;
    background: var(--card-action-primary-color);
    color: var(--card-action-secondary-color);
    @media (max-width: 500px) {
        font-size: 10px;
        line-height: 12px;
        padding: 4px 8px;
    }
    &:hover {
        cursor: pointer;
        background: color-mod(var(--card-action-primary-color) blackness(+10%));
    }
}

.delivery-option-button.active {
    background: color-mod(var(--card-action-primary-color) blackness(+10%));
}

.delivery-option-button.disabled {
    pointer-events: none;
    background: color-mod(var(--card-action-primary-color) lightness(+25%));
}

.delivery-way {
    display: none;
}

.delivery-way.active {
    display: flex;
    flex-flow: column nowrap;
}

.delivery-form {
    display: flex;
    flex-flow: row wrap;
}

.delivery-form .feedback-form__input-wrapper {
    margin: 8px;
}

.delivery-payments{
    display: flex;
    @media (max-width: 770px) {
        div {
            flex-basis: 40%;
        }
    }
}

.delivery-type {
    display: flex;
    padding-bottom: 10px;
}

.delivery-payments {
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 770px) {
        justify-content: space-between;

        div {
            flex-basis: 30%;
            margin-top: 20px;
            margin-left: 0;
        }
    }
}

.delivery-payment__title {
    @media (max-width: 600px) {
        margin-top: 0;
    }
}

.delivery-payment-type {
    margin-left: 20px;
    cursor: pointer;
    width: 150px;
    height: 150px;
    padding: 15px 20px 15px 20px;
    border-radius: 15px;
    border: 1px solid #33333380;
}

.delivery-payment-type-active {
    border-color: var(--footer-title-color);
}

.radio-wrapper {
    display: flex;
    flex-flow: row nowrap;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    font-size: 24px;
    letter-spacing: 1.5px;
    margin: 8px 0;
    & input {
        margin: 0 4px 0 0;
    }

    @media (max-width: 600px) {
        font-size: 16px
    }
    @media (max-width: 380px) {
        font-size: 12px
    }
}

.radio-wrapper:last-child {
    margin-left: 20px;
}

input[type='radio']:after {
    background: var(--footer-title-color);
    border: var(--footer-title-color);
}

input[type="radio"]:checked:after {
    background: var(--footer-title-color);
    border: var(--footer-title-color);
}

.radio-button {
    border: var(--footer-title-color);
    width: 24px;
    height: 24px;
}


/* delivery and payment */

.delivery-and-payment {
    padding: 20px;
}

.d-and-p--content-padding {
    padding: 20px 0 0 0;
}

.delivery-and-payment__cards {
    display: flex;
    justify-content: space-between;
    @media (max-width: 767px) {
        flex-basis: 80%;
        flex-direction: column;
    }
}

.delivery-and-payment__card {
    box-shadow: var(--shadow);
    flex-flow: column nowrap;
    justify-content: flex-start;
    padding: 20px 20px 20px 0;
    flex-basis: 20%;
    color: var(--card-item-primary-color);
    @media (max-width: 767px) {
        margin-bottom: 30px;
        align-items: center;
        display: flex;
        text-align: center;
    }
}

.delivery-and-payment__card-img {
    margin-left: 10px;
    padding-bottom: 10px;
}

.delivery-and-payment__card-title {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    padding-bottom: 15px;
    color: var(--footer-title-color);
}

.delivery-and-payment__card-description {
    padding: 0 0 0 8px;
    margin: 0;
    font-style: normal;
    font-weight: normal;
    line-height: 19.36px;
    font-size: 16px;
    letter-spacing: 0.5px;
}

.delivery-and-payment__google_map_container {
    width: 100%;
    height: 750px;
    iframe {
        border: none;
    }
}

.payment-result,
.delivery-and-payment__delivery {
    display: flex;
    padding: 20px;
    margin-top: 40px;
    justify-content: space-around;

    .delivery-and-payment__margin-left {
        margin-left: 20px;
    }

    @media (max-width: 1200px) {
        margin-bottom: 30px;
        flex-direction: column;


        .order-delivery {
            order: -1;
        }
    }
}

.delivery-and-payment__delivery_card {
    flex: 1;
    padding: 50px 34px 50px 34px;
    height: fit-content;
    background: #FFFFFF;
    border-radius: 15px;
    p {
        margin-top: 10px;
        font-size: 20px;
    }
    .feedback-form__input-wrapper {
        margin-top: 50px;
    }
    .delivery-and-payment__delivery_card_block_button {
        display: flex;
        justify-content: end;
    }
    @media (max-width: 1200px) {
        margin-left: 0!important;
        margin-right: 0!important;
    }
}

.payment-result {
    max-width: 1064px;
    margin: 0 auto;
    background: #F2F2F2;
    border-radius: 15px;
    align-items: center;
    .payment-result__card-image {
        width: 500px;
        height: 500px;
    }

    @media (max-width: 1024px) {
        flex-direction: column;
    }

    @media (max-width: 767px) {
        .payment-result__card-image {
            width: 326px;
            height: 326px;
        }
        .payment-result__card > div {
            justify-content: center;
        }
    }
}

.payment-result__card {
    .payment-result__card_block_button,
    .payment-result__card-header,
    .payment-result__card-text {
        max-width: 391px;
    }
    .payment-result__card-header {
        font-size: 32px;
        font-weight: 500;
        margin: 0;
    }
    .payment-result__card-text {
        font-size: 20px;
        margin: 30px 0 50px 0; 
    }
    .payment-result__card_block_button {
        display: flex;
        justify-content: end;        
        margin: 0;
    }
    .feedback-form__button {
        text-transform: none;
    }
}

.delivery-address {
    display: flex;
    justify-content: space-between;

    div:first-child {
        flex-basis: 100%;
    }
    div:nth-child(n+1) {
        flex-basis: 22%;
    }
}

.delivery-and-payment__delivery_prices {
    padding: 0 10px 10px 0;
}

.address-form__address-short-field {
    flex-basis: 22%!important;
}

.block-button-accept-order {
    justify-content: end;
    display: flex;
}

/* index */
.index {
    padding: 20px 0 55px 0;
    @media (max-width: 768px) {
        padding: 0 0 55px 0;
    }
    @media (max-width: 320px) {
        padding: 10px 4px 0 8px;
    }
}

.featured-categories {
    display: flex;
    flex-flow: column nowrap;
    padding: 20px 0;
    margin: 0 8px;
}

.featured-categories__title {
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 34px;
    letter-spacing: 0.25px;
    color: var(--block-title-color);
    margin-top: 0;
    margin-bottom: 20px;
}

.featured-categories__content {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
}

.featured-categories__content_justify-to-start {
    justify-content: flex-start;
    @media (max-width: 768px) {
        justify-content: space-between;
    }
}

.featured-categories__catalog-link-button-wrapper {
    display: flex;
    justify-content: center;
    padding: 0 0 24px 0;
}

.category-card {
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    box-shadow: var(--shadow);
    transition: 0.25s;
    border-radius: 0 50px;
    overflow: hidden;
    margin: 0 0 32px 0;

    &:hover .category-card__description {
        opacity: 1;
    }

    &:hover {
        box-shadow: var(--shadow-hover);
        transform: translateY(-4px);
        opacity: 0.7;
    }

    @media (max-width: 576px) {
        border-radius: 0 20px;
    }
}

.category-card--justify-to-start {
    margin-right: 20px;

    @media (max-width: 768px) {
        margin-right: 0;
    }
}

.category-card__image {
    display: flex;
    //height: 220px;
    height: 324px;
    filter: brightness(70%);
    border-radius: 0 50px;

    @media (max-width: 576px) {
        height: 120px;
        border-radius: 0 20px;
    }
}

.category-card__image_placeholder {
    opacity: 0;
}

.category-card__content-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-flow: column nowrap;
    padding: 15px;
    z-index: 23;
}

.category-card__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    text-align: center;
}

.category-card__title {
    margin: 0;
    font-weight: 500;
    text-shadow: 0px 1px 3px black;
    line-height: 20px;
    font-size: 17px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: var(--main-secondary-color);
}

.category-card__description {
    padding: 0 8px;
    line-height: 20px;
    font-size: 14px;
    letter-spacing: 0.25px;
    opacity: 0;
    transition: opacity 0.25s;
    color: var(--main-font-color);
    @media (max-width: 768px) {
        opacity: 0.7;
    }
    @media (max-width: 576px) {
       display: none;
    }
}

.catalog-link-button {
    display: inline-block;
    border: none;
    border-radius: 200px;
    padding: 8px 16px 8px 16px;
    outline: none;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    font-size: 18px;
    text-align: center;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    text-decoration: none;
    background: var(--card-action-primary-color);
    color: var(--card-action-secondary-color);
    &:hover {
        cursor: pointer;
        background: color-mod(var(--action-item-primary-color) blackness(+10%));
    }
}


/*Table order style*/
.table-order{
    background: var(--card-bg-color);
    color: var(--card-item-primary-color);
    box-shadow: var(--shadow);
    border-radius: 4px;
    display: flex;
    flex-flow: column nowrap;
    padding: 15px;
    @media (min-width: 600px) {
        min-width: 550px;
    }
}

.table-order__identify{
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    border: 2px solid var(--card-action-primary-color);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 28px;
}

.table-order__identify label{
    position: absolute;
    top: -10px;
    padding: 0 2px 0 2px;
    font-style: normal;
    font-weight: normal;
    line-height: 16px;
    font-size: 12px;
    letter-spacing: 0.4px;
    color: var(--card-action-primary-color);
    background: var(--card-bg-color);
}

.table-order__identify p{
    width: 100%;
    border: none;
    outline: none;
    font-style: normal;
    font-weight: normal;
    margin: 5px;
    line-height: 20px;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: var(--input-font-color);
    background: var(--card-bg-color);
}

.table-order__description p{
    margin: 5px;
}

/* order status */

.order-status {
    padding: 20px;
}

.order-status__content-wrapper {
    box-shadow: var(--shadow);
    border-radius: 4px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    padding: 20px;
    background: var(--card-bg-color);
    color: var(--card-item-primary-color);
}

.last-order {
    flex-basis: 100%;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    font-size: 18px;
    letter-spacing: 1.5px;
    color: var(--card-item-primary-color);
}

.last-order span {
    font-style: normal;
    font-weight: normal;
    line-height: 28px;
    font-size: 18px;
    letter-spacing: 0.5px;
    opacity: 0.6;
}

.order-wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    flex-basis: 100%;
    @media (max-width: 767px) {
        flex-flow: row wrap;
    }
}

.order-id {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    flex-basis: 49.9%;
    padding: 20px 0 0 0;
    @media (max-width: 320px) {
        flex-flow: row wrap;
    }
}

.order-id button {
    background: none;
    display: inline-block;
    border: none;
    border-radius: 200px;
    padding: 8px 16px 8px 16px;
    margin: 0 0 0 8px;
    outline: none;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    background: var(--card-action-primary-color);
    color: var(--card-action-secondary-color);
    @media (max-width: 320px) {
        margin: 10px 0 0 0;
    }
}

.order-id button:hover {
    background: color-mod(var(--card-action-primary-color) blackness(+10%));
}

.order-id label {
    position: absolute;
    top: 12px;
    left: 8px;
    padding: 0 2px 0 2px;
    font-style: normal;
    font-weight: normal;
    line-height: 16px;
    font-size: 12px;
    letter-spacing: 0.4px;
    color: var(--card-item-secondary-color);
    background: var(--card-bg-color);
}

.order-id input {
    outline: none;
    border: 2px solid var(--card-item-secondary-color);
    box-sizing: border-box;
    border-radius: 200px;
    padding: 8px 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: var(--search-font-color);
    background: var(--card-bg-color);
    min-width: 200px;
    @media (max-width: 767px) {
        min-width: 50px;
    }

    @media (max-width: 320px) {
        min-width: 200px;
    }
}

.order-status {
    display: flex;
    flex-flow: column nowrap;
    flex-basis: 49.9%;
    padding: 20px 0 0 0;
    min-height: 130px;
}

.order-status h1 {
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-size: 20px;
    letter-spacing: 0.15px;
}

.order-status__none {
    padding: 0 0 0 8px;
    font-style: normal;
    font-weight: normal;
    line-height: 28px;
    font-size: 18px;
    letter-spacing: 0.5px;
    opacity: 0.6;
    color: var(--card-item-primary-color);
    @media (max-width: 320px) {
        width: 100%;
    }
}

.order-status__found {
    padding: 0 0 0 8px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    font-size: 18px;
    letter-spacing: 1.5px;
    color: var(--card-item-primary-color);
}

.order-status__found span {
    font-style: normal;
    font-weight: normal;
    line-height: 28px;
    font-size: 18px;
    letter-spacing: 0.5px;
    opacity: 0.6;
}

/* product */
.product {
    padding: 20px;
}

.product__content-wrapper {
    display: flex;
    flex-flow: column nowrap;
}

.product__header {
    display: flex;
    flex-flow: row nowrap;
}

.product__title {
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 34px;
    letter-spacing: 0.25px;
    color: var(--block-title-color);
    margin: 0;
}

.product__price {
    display: inline-block;
    border: none;
    border-radius: 200px;
    padding: 8px 16px 8px 16px;
    outline: none;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    font-size: 18px;
    text-align: center;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    background: var(--card-action-primary-color);
    color: var(--card-action-secondary-color);
}

.product__main-block {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    padding: 20px 0;
    @media (max-width: 576px) {
        flex-flow: column nowrap;
        justify-content: space-around;
    }
}

.product__image-wrapper {
    border-radius: 15px;
    overflow: hidden;
}

.product__image {
    height: 300px;
    @media (max-width: 374px) {
        height: 250px;
    }
}

.product__options-wrapper {
    display: flex;
    flex-flow: column nowrap;
    padding: 0 20px;
}

.product__options {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    padding: 0 0 16px 0;
    flex-direction: column;
    @media (max-width: 576px) {
        flex-flow: column nowrap;
        justify-content: space-around;
    }
}

.option-select {
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    margin: 4px;
    min-width: 225px;
    & label {
        font-style: normal;
        font-weight: normal;
        line-height: 24px;
        font-size: 14px;
    }
}

.option-select--custom {
    background-color: var(--card-bg-color);
    box-shadow: var(--shadow);
    border: none;
    border-radius: 4px;
}

.option-select__items div,
.option-select--custom {
    color: var(--card-item-primary-color);
    padding: 8px 16px;
    border: none;
    cursor: pointer;
}

.option-select__items {
    position: absolute;
    background-color: var(--card-bg-color);
    top: 95%;
    left: 0;
    right: 0;
    z-index: 99;
    box-shadow: var(--shadow);
    border-radius: 4px;
}

.option-select--hidden {
    display: none;
}

.option-select__items div:hover,
.same-as-selected {
    border-radius: 4px;
    background: color-mod(var(--card-bg-color) blackness(10%));
}

/*arrow*/
.option-select--custom:after {
    position: absolute;
    content: "";
    top: 38px;
    right: 10px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-color: #000000 transparent transparent transparent;
}
.option-select--custom.select-arrow-active:after {
    border-color: transparent transparent #000000 transparent;
    top: 31px;
}

.select-arrow-active.option-select--custom {
    background: color-mod(var(--card-bg-color) blackness(10%));
}

.option-quantity {
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    margin: 4px;
    min-width: 225px;
    & label {
        font-style: normal;
        font-weight: normal;
        line-height: 24px;
        font-size: 14px;
    }
}

.option-quantity__actions {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    background-color: var(--card-bg-color);
    box-shadow: var(--shadow);
    border: none;
    border-radius: 4px;
    max-width: 70px;
    padding: 6px;
    & button {
        border: none;
        background: none;
        outline: none;
    }

    & button:hover {
        cursor: pointer;
        color: var(--card-action-primary-color);
        outline: none;
    }
}

.product__description {
    display: flex;
    flex-flow: column nowrap;
    font-style: normal;
    font-weight: normal;
    line-height: 25px;
    font-size: 15px;
    letter-spacing: 0.25px;
    color: var(--block-title-color);
    margin: 5px 0;
    & p {
        text-align: justify;
    }

    & a {
        text-decoration: none;
        color: var(--action-item-primary-color);
    }

    & a:hover {
        text-decoration: underline;
    }
}

.product__description-specs {
    display: flex;
    flex-flow: column nowrap;
}

.spec {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background: var(--card-bg-color);
    padding: 0 4px;
}

.spec:nth-child(2n + 1) {
    background: color-mod(var(--card-bg-color) blackness(10%));
}

.spec--title {
    padding: 8px 4px;
}

/* choose address */

.choose-address__container {
    display: flex;
    justify-content: center;
}

.choose-address__card {
    border-radius: 15px;
    padding: 42px 89px 42px 89px;
    background: white;
    max-width: 1000px;
    width: 100%;
    @media (max-width: 1100px) {
        padding: 20px 20px 20px 20px;
    }
    @media (max-width: 600px) {
        padding: 10px 15px 10px 15px;
    }
}

.choose-address__card-title {
    font-weight: 700;
    font-size: 24px;
}

.choose-address__card-radio {
    display: flex;
    padding: 15px 20px 25px 0;
    font-size: 24px!important;
    justify-content: space-between;
    @media (max-width: 700px) {
       padding: 15px 0 15px 0;
    }
}

.choose-address__card-radio-delivery {
    line-height: 38px;
    @media (max-width: 700px) {
        line-height: 20px;
        font-size: 16px;
        text-align: right;
        font-weight: bold;
        margin-left: 10px;
        display: flex;
        align-items: center;
    }
    @media (max-width: 380px) {
        font-size: 12px;
    }
}

.choose-address__card-radio-block {
    display: flex;
    div:nth-child(2) {
        margin-left: 15px;
    }
    @media (max-width: 400px) {
        font-size: 15px;
    }
}

.choose-address__card_map {
    width: 100%;
    height: 580px;
    iframe {
        border: none;
    }
    @media (max-width: 1100px) {
        height: 350px;
    }
}

.choose-address__card-delivery {
    display: flex;
    @media (max-width: 874px) {
        flex-direction: column;
        padding-bottom: 20px;
    }
}

.choose-address__card-delivery-input {
    flex: 1;
}

.choose-address__card-delivery-button {
    padding-left: 20px;
    @media (max-width: 874px) {
        justify-content: center;
        display: flex;
        padding-left: 0;
    }
}

.hidden-map {
    visibility: hidden;
    display: none;
}

/* products */
.catalog {
    padding: 20px;
    @media (max-width: 374px) {
        padding: 20px 8px 0 8px;
    }
}

.catalog__title {
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 34px;
    letter-spacing: 0.25px;
    color: var(--block-title-color);
    margin: 0;
}

.categories-wrapper{
    overflow-x: scroll;
}

.categories-2wrapper{
    position: relative;
    background-color: var(--card-bg-color);
}

.categories-2wrapper:after {
    content: '';
    position: absolute;
    right:0;
    top:0;
    bottom: 0;
    width: 120px;
    background-color: var(--card-bg-color);
    z-index: 15;
}

.categories {
    list-style-type: none;
    display: flex;
    position: sticky;
    top: 90px;
    z-index: 1;
    flex-flow: column wrap;
    justify-content: flex-start;
    padding: 8px;
    margin: 0;
    box-shadow: var(--shadow);
    border: none;
    border-radius: 4px;
    background: var(--card-bg-color);
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    font-size: 14px;
    letter-spacing: 1.5px;
    min-width: 240px;
    background: var(--card-bg-color);
    @media (max-width: 768px) {
        display: none;
    }
    @media (max-width: 1200px) {
        top: 100px;
    }
}

.categories_top {
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 3px 8px;
    box-shadow: none;
    border-radius: 0;
    width: max-content;
    padding-right: 120px;
    .accordion-item{
        width: auto;
    }
    .accordion__header{
        padding: 4px 40px;
    }
}

.categories_scroll {
    cursor: grab;
}

.categories--top {
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 3px 8px;
    box-shadow: none;
    border-radius: 0;
    width: max-content;
    padding-right: 120px;
    .accordion-item{
        width: auto;
    }
    .accordion__header{
        padding: 4px 40px;
    }
}

.categories--scroll {
    cursor: grab;
}

.categories__item {
    text-decoration: none;
    color: var(--card-action-primary-color);
    padding: 8px;
    &:hover {
        color: var(--card-item-primary-color);
    }
}

.subcategories {
    position: relative;
    min-width: 200px;
    padding: 8px 0;
}

.subcategories:hover {
    background: color-mod(var(--card-bg-color) blackness(5%));
}

.subcategories .categories {
    display: none;
    position: absolute;
    top: 30px;
    left: 75%;
    margin-top: -1px;
    z-index: 15;
    box-shadow: var(--shadow);
}

.subcategories:nth-child(2n) {
    background: color-mod(var(--card-bg-color) blackness(2.5%));
}

.categories .subcategories:hover > .categories {
    display: flex;
    z-index: 10;
}

.accordion-item {
    display: flex;
    flex-flow: column nowrap;
    overflow: auto;
}

.accordion-item {
    background: var(--card-bg-color);
}

.accordion__header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 4px 16px;
    &:hover {
        background: color-mod(var(--card-bg-color) blackness(2.5%));
    }
}

.accordion-arrow {
    color: var(--card-action-primary-color);
    width: 24px;
    height: 24px;
}

.accordion__header .accordion-arrow {
    transform: rotate(90deg);
}

.accordion__header_open .accordion-arrow {
    transform: none;
}

.accordion-item__content {
    display: none;
    overflow: auto;
    padding: 4px;
}

.accordion-item__content_category {
    flex-flow: column nowrap;
    padding: 0 0 0 20px;
}

.menu-filter-combo{
    position: sticky;
    top: 0px;
    z-index:15;
}

.filters {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    position: sticky;
    top: 0px;
    padding: 0 0 8px 0;
    z-index: 15;
    background: var(--main-bg-color);
    @media (max-width: 768px) {
        display: none;
    }
}

.filters--combo{
    /*align-self: flex-end;
    flex-grow: 2;*/
}

.filters__actions {
    display: flex;
    flex-flow: row nowrap;
}

.filter__button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    border-radius: 200px;
    padding: 8px;
    margin: 8px 8px 8px 0;
    outline: none;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    text-decoration: none;
    background: var(--card-action-primary-color);
    color: var(--card-action-secondary-color);
    &:hover {
        cursor: pointer;
        background: color-mod(var(--card-action-primary-color) blackness(+10%));
    }
}

.dropmic--show > .filter__button {
    background: color-mod(var(--card-action-primary-color) blackness(+25%));
}

.filter__button--drop {
    background: var(--main-error-color);
    &:hover {
        background: color-mod(var(--main-error-color) blackness(+10%));
    }
}

.filter__button--apply {
    margin: 8px 8px 0 0;
}

.filter__dropdown {
    display: flex;
    flex-flow: column nowrap;
    border: none;
    z-index: 25;
    padding: 8px;
    min-height: 40px;
    box-shadow: var(--shadow);
    overflow-y: scroll;
    background: var(--dropdown-card-bg-color);
}

.filter__dropdown-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 8px 0 0 0;
    border-top: 1px solid color-mod(var(--dropdown-card-item-color) lightness(80%));
    background: var(--dropdown-card-bg-color);
}

.price-filter-wrapper {
    display: flex;
    flex-flow: column nowrap;
}

.price-filter {
    display: flex;
    flex-flow: row nowrap;
    margin: 4px 0 4px 0;
}

.price-filter__label {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 4px 0 0 4px;
    padding: 8px 16px 8px 16px;
    outline: none;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    font-size: 10px;
    text-align: center;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    background: var(--dropdown-card-button-bg-color);
    color: var(--dropdown-card-button-font-color);
}

.price-filter__input {
    border: 2px solid var(--dropdown-card-button-bg-color);
    border-radius: 0 4px 4px 0;
    box-sizing: border-box;
    padding: 6px;
    width: 80px;
    outline: none;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    @media (max-width: 768px) {
        width: 60px;
    }
}

.filter__title {
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    font-size: 10px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.filter__options {
    list-style-type: none;
    padding: 0 8px;
    margin: 4px 0;
    max-height: 80px;
    overflow: hidden;
    overflow-y: scroll;

    & li {
        padding: 4px 4px 4px 0;
    }

    & li:nth-child(2n) {
        background: color-mod(var(--dropdown-card-bg-color) blackness(5%));
    }
}

.filter__option {
    display: flex;
    align-items: center;
    padding: 4px 4px 4px 0;
    & .option-label {
        display: inline-block;
        margin: 0 0 0 8px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        font-size: 14px;
        letter-spacing: 1.5px;
    }
    & .option-label:hover {
        color: var(--dropdown-card-action-color);
    }
    &:hover .checkbox + label {
        background: color-mod(var(--dropdown-card-action-color) lightness(90%));
    }
}

.filters__item {
    padding: 8px 8px 8px 0;
}

.catalog__subheader {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
    @media (min-width: 769px) {
        display: none;
    }
}

/* This is fix for plagin sidebarjs, that they were instantly hide from screen */

.sidebarjs--left {
    transform: translate(-100%);
}

.sidebarjs--right {
    transform: translate(100%);
    top: 69px;
}

.sidebar-accordion-item {
    display: flex;
    flex-flow: column nowrap;
    overflow: auto;
    background: var(--sidebar-bg-color);
    letter-spacing: 1px;
}

.sidebar-accordion__header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 4px 16px;
}

.sidebar-accordion__header .sidebar-accordion-arrow {
    transform: rotate(90deg);
}

.sidebar-accordion__header_open .sidebar-accordion-arrow {
    transform: none;
}

.sidebar-accordion-item__content {
    display: none;
    overflow: auto;
    padding: 4px 0px 4px 8px;
}

.sidebar-accordion-item__content_filters {
    flex-flow: column nowrap;
}

.filter-mobile {
    padding: 6px 0 8px 12px;
    display: flex;
    align-items: center;
    color: var(--dropdown-card-item-color);
    & > .option-label {
        line-height: 20px;
        font-size: 14px;
        letter-spacing: 1.5px;
    }
}

.sidebar-accordion-item__content_category {
    flex-flow: column nowrap;
}

.price-filter-mobile {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.price-filter-wrapper--mobile {
    width: 100%;
}

.filter__options--mobile {
    width: 100%;
}

.products-grid-wrapper {
    padding: 4px;
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-column-gap: 20px;
    @media (min-width: 1200px) {
        grid-column-gap: 70px;
    }
    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
}

.products {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    @media (max-width: 576px) {
        justify-content: space-around;
    }
}

.products__empty-page {
    color: var(--block-title-color);
}

.pagination__wrapper {
    display: flex;
    justify-content: center;
}

.pagination {
    display: flex;
    flex-flow: row nowrap;
    list-style: none;
    padding: 0;
}

.pagination__page {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--card-bg-color);
    color: var(--card-item-primary-color);
    padding: 8px;
    margin: 8px;
    border-radius: 4px;
    & a {
        color: var(--card-item-primary-color);
        text-decoration: none;
    }

    & a:hover {
        color: var(--card-action-primary-color);
    }

    @media (max-width: 424px) {
        margin: 4px;
    }
}

.pagination__current {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--card-action-primary-color);
    color: var(--card-action-secondary-color);
    padding: 8px;
    margin: 8px;
    border-radius: 4px;
    @media (max-width: 424px) {
        margin: 4px;
    }
}

.pagination__button {
    border: none;
    border-radius: 0;
    align-items: center;
    display: flex;
    & a {
        color: var(--card-item-primary-color);
    }
}

.pagination__button:hover {
    & a {
        color: var(--card-action-primary-color);
    }
}
/* components */

/* featured products */
.featured-products {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin: 0 8px;

    @media (max-width:768px) {
        margin: 0;
    }
}

.featured-products__title {
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 34px;
    letter-spacing: 0.25px;
    color: var(--block-title-color);
    margin-top: 0;
}

/* feedback form */

.feedback-form {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    background: var(--card-bg-color);
    border-radius: 4px;
    box-sizing: border-box;
    @media (max-width: 320px) {
        min-width: 250px;
    }
}

.feedback-form-padding {
    padding: 28px;
}

.feedback-form__checkbox {
    display: flex;
    flex-flow: row nowrap;
    padding: 0 0 16px 0;
}

.feedback-form__checkbox-label {
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    font-size: 14px;
    letter-spacing: 1.5px;
    color: var(--card-item-primary-color);
    & a {
        color: var(--card-action-primary-color);
        text-decoration: none;
    }

    & a:hover {
        text-decoration: underline;
    }
}

.feedback-form__checkbox-label.invalid a {
    color: var(--main-error-color);
}

.feedback-form__checkbox-content {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

.feedback-form__output-wrapper_half {
    box-sizing: border-box;
    flex-basis: 40%;
    margin: 8px;
    padding: 10px;
    @media (max-width:560px) {
        flex-basis: 90%;        
    }
}

.feedback-form__input-wrapper_half {
    flex-basis: 40%;
    @media (max-width:560px) {
        flex-basis: 90%;        
    }
}

.delivery-time {
    display: flex;
    justify-content: space-between;

    @media (max-width: 600px) {
        flex-direction: column;
    }
}

.delivery-interval {
    width: 45%;
    @media (max-width: 600px) {
        margin-top: -20px;
        width: 100%;
    }
}

.delivery-date {
    width: 45%;
    @media (max-width: 600px) {
        width: 100%;
    }
}
.delivery-time-title {
    margin-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.delivery-title {
    margin-left: 20px;
    padding-bottom: 10px;
    margin-top: 0px;
    margin-bottom: 30px;
}

.delivery-intervals-select {
    width:100%;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    grid-area: select;
    border: none;
    font-size: 14px;
}

.delivery-intervals-select__triangle {
    width:100%;
    display: grid;
    grid-template-areas: "select";
    align-items: center;
    position: relative;
    padding: 0 10px;
    border: 1px solid black;
    border-radius: 5px;
    border: none;
}

.delivery-intervals-select__triangle:after {
    grid-area: select;
    content: "";
    justify-self: end;
    width: 0.8em;
    height: 0.5em;
    background-color: var(--main-font-color);
    opacity:.6;
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
}

.feedback-form__input-wrapper {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    border: 1px solid #33333380;
    box-sizing: border-box;
    border-radius: 15px;
    padding: 15px;
    margin-bottom: 28px;
}

.feedback-form__input-wrapper input {
    width: 100%;
    border: none;
    outline: none;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    font-size: 16px;
    letter-spacing: 0.25px;
    color: var(--input-font-color);
    background: var(--card-bg-color);
}

.disabled__input {
    background: gainsboro;
}

.feedback-form__input-wrapper .prompt-list {
    z-index: 9999;
    position: absolute;
    top: 46px;
    left: 2px;
    width: 100%;
    border-radius: 15px;
    border: black 1px solid;
    background: #FFFFFF;
    padding: 15px 0 15px 15px;

    .prompt {
        line-height: 19px;
        font-size: 16px;
        border-radius: 15px;
        cursor: pointer;
        padding: 8px 0 8px 20px;
    }

    .prompt:hover {
        background: var(--footer-title-color);
    }

    div:last-child {
        border-bottom: black 1px solid;
    }
}

.feedback-form__input-wrapper input::placeholder {
    color: color-mod(var(--input-font-color) lightness(60%));
}

.feedback-form__input-wrapper label {
    position: absolute;
    top: -20px;
    padding: 0 2px 0 2px;
    font-style: normal;
    font-weight: normal;
    line-height: 16px;
    font-size: 12px;
    letter-spacing: 0.4px;
    color: #33333380;
    background: var(--card-bg-color);
}

.feedback-form__input-wrapper span {
    position: absolute;
    bottom: -18px;
    font-style: normal;
    font-weight: normal;
    line-height: 16px;
    font-size: 12px;
    letter-spacing: 0.4px;
    color: var(--main-error-color);
}

.feedback-form__input-wrapper.invalid{
    border: 2px solid var(--main-error-color);
}

.feedback-form__input-wrapper label.invalid {
    color: var(--main-error-color);
}

.feedback-form__textarea-wrapper {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    padding: 0 0 24px 0;
}

.feedback-form__textarea-wrapper textarea {
    outline: none;
    resize: none;
    border-radius: 4px;
    border: 2px solid var(--card-action-primary-color);
    padding: 8px;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: var(--input-font-color);
    background: var(--card-bg-color);
}

.feedback-form__textarea-wrapper textarea::placeholder {
    color: color-mod(var(--input-font-color) lightness(60%));
}

.feedback-form__textarea-wrapper label {
    position: absolute;
    top: -8px;
    left: 9px;
    padding: 0 2px 0 2px;
    font-style: normal;
    font-weight: normal;
    line-height: 16px;
    font-size: 12px;
    letter-spacing: 0.4px;
    color: var(--card-action-primary-color);
    background: var(--card-bg-color);
}

.feedback-form__button {
    display: inline-block;
    border: none;
    border-radius: 200px;
    padding: 8px 16px 8px 16px;
    outline: none;
    width: 300px;
    height: 50px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    font-size: 16px;
    text-align: center;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    background: var(--footer-title-color);
    color: var(--card-action-secondary-color);
    &:hover {
        background: #F7967D;
        cursor: pointer;
    }
    &:active {
        background: #9B2708;
    }
    &:disabled {
        background: #E0E0E0;
        color: #8B8B8B;
    }
}

.feedback-form__button-large {
    width: 100%;
}

.scalable-img {
    width: 100%;
}

/* product card */

.card {
    display: flex;
    flex-flow: column nowrap;
    box-shadow: var(--shadow);
    transition: 0.25s ease-out;
    border-radius: 0 50px;
    overflow: hidden;
    width: 23%;
    margin-bottom: 32px;
    margin:10px;
    &:hover {
        box-shadow: var(--shadow-hover);
        transform: translateY(-4px);
    }
}

.card__image-wrapper {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    cursor: pointer;
}

.card__image {
    display: flex;
    width: 100%;
    border-radius: 0 50px 0 0;
}

.card__image--none {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    background: none;
    color: var(--card-font-color);
    border-radius: 15px;
}

.card__image--none::before {
    content: '\f82a';
    color: lightgray;
    padding: 95px;
    display: inline-block;
    font: normal normal normal 24px/1 "Material Design Icons";
    font-size: 70px;
    text-rendering: auto;
    line-height: inherit;
}

.card__image-label {
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 11px 21px;
    background: #EF8B18;
    border: 2px solid #EF8B18;
    border-radius: 20px;
    font-weight: 900;
    font-size: 14px;
    line-height: 17px;
    color: #F1F1F1;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.card__info {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    padding: 11px 20px;
    background: #F2F2F2;
}

.card__main-info {
    display: flex;
    justify-content: space-between;
}

.card__title {
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-size: 20px;
    line-height: 24px;
    color: #333333;
}

.card__price {
    font-family: 'Inter';
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 20px;
    line-height: 24px;
    color: #333333;
    min-width: 70px;
    padding: 8px 0;
}

.card__description {
    font-style: normal;
    font-weight: 300;
    line-height: 19px;
    font-size: 16px;
    color: rgba(51, 51, 51);
    opacity: .5;
    min-height: 32px;
}

.card__description p {
    margin: 20px 0;
}

.card__actions {
    margin: auto 0 0 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.card__button {
    display: inline-block;
    border: none;
    border-radius: 20px;
    min-width: 152px;
    padding: 10px;
    outline: none;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    color: #F2F2F2;
    background: #EF4618;
    &:hover {
        background: #F7967D;
        cursor: pointer;
    }
    &:active {
        background: #9B2708;
    }
    &:disabled {
        background: #E0E0E0;
        color: #8B8B8B;
    }
}

.card__button_disabled {
    opacity: 0.6;
}

.card__button_disabled:hover {
    cursor: default;
    opacity: 0.6;
    color: var(--card-action-primary-color);
}

/* product card (adaptive) */
@media (max-width: 1200px) {
    .card {
        width: 212px;
        height: 400px;
    }

    .card__image {
        height: 183px;
    }

    .card__info {
        padding: 8px 17px 0 14px;
    }

    .card__title {
        margin-bottom: 10px;
    }

    .card__description p {
        margin: 0 0 6px 0;

    }

    .card__actions {
        margin-bottom: 16px;
    }

    .card__price {
        padding: 4px 0;
    }

    .card__button {
        font-size: 12px;
        font-weight: 500;
        min-width: 99px;
        max-width: 109px;
        padding: 8px 10px;
        line-height: 14px;
        height: 31px;
        white-space: nowrap;
    }

}
/* product card (mobile) */
@media (max-width: 490px) {
    .card {
        width: 95%;
        flex-direction: row;
        border-radius: 20px;
        height: 130px;
    }

    .card__image {
        width: 130px;
        height: 130px;
        border-radius: 20px 0 0 20px;
    }

    .card__image-label {
        display: none;
    }

    .card__image-wrapper {
        width: auto;
    }

    .card__info {
        /*border-radius: 0 20px 0 0;*/
        padding: 5px 10px;
    }

    .card__title {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 4px;
    }

    .card__price {
        font-size: 16px;
        font-weight: 500;
        padding: 4px 0;
    }

    .card__description {
        font-size: 12px;
        font-weight: 300;
        flex-shrink: 2;
        p {
            margin: 0;
            line-height: 15px;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    .card__button {
        font-size: 12px;
        font-weight: 500;
        min-width: 99px;
        max-width: 109px;
        padding: 8px 10px;
        line-height: 14px;
        height: 31px;
        white-space: nowrap;
    }

    .featured-products .around-xs {
        flex-direction: column;
    }
}

/* slider */

.admin-slider {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;
    padding: 10px 0;
    border-bottom: 1px solid #e5e5e5;
}

.admin-slider__title {
    margin: 0;
    line-height: 26px;
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 0.5px;
}

.admin-slider__link {
    position: relative;
    text-decoration: none;
    border-radius: 200px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-width: 100px;
    min-height: 34px;
    border: none;
    padding: 5px 20px;
    outline: none;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    background: var(--header-item-color);
    color: var(--header-bg-color);
}

.admin-slider__link:hover {
    background: color-mod(var(--header-item-color) blackness(+10%));
    cursor: pointer;
}

.slider-wrapper {
    position: relative;
    background: #333333;
}

.slider__container {
    height: 100%;
}

.slide {
    position: relative;
}

.slide__shadow {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 22;
    background: var(--slide-overlay);
}

.prev,
.next {
    position: absolute;
    top: calc(50% - 36px / 2);
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    color: var(--slider-action-secondary-color);
    &:hover {
        color: var(--slider-action-primary-color);
    }
}

.prev {
    left: 0;
}

.next {
    right: 0;
}

.slide__img {
    width: 100%;
}

.slide__content {
    z-index: 23;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: flex-end;
    padding: 20px;
}

.slide__info {
    display: flex;
    flex-flow: column nowrap;
}

.slide__title {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 34px;
    letter-spacing: 0.25px;
    @media (max-width: 767px) {
        font-size: 24px;
    }
}

.slide__body {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    line-height: 28px;
    font-size: 18px;
    letter-spacing: 0.5px;
    @media (max-width: 767px) {
        line-height: 16px;
        font-size: 12px;
    }
}

.slide__button {
    text-decoration: none;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    font-size: 20px;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    color: var(--slider-action-primary-color);

    &:hover {
        color: var(--slider-action-secondary-color);
    }
    @media (max-width: 767px) {
        line-height: 18px;
        font-size: 16px;
    }
}

/* slide List */

.slide-list {
    position: relative;
    min-height: inherit;
    padding: 20px 0;
    @media (max-width: 425px) {
        padding: 20px 0;
    }
}

.slide-list__content {
    counter-reset: li; 
    list-style: none; 
    font: 14px "Trebuchet MS", "Lucida Sans";
    padding: 0;
    text-shadow: 0 1px 0 rgba(255,255,255,.5);
}

.slide-list__content li {
    display: flex;
    margin-bottom: 10px;
}

.slide-list__content a {
    position: relative;
    display: block;
    border-radius: 5px;
    width: 100%;
    padding: .4em .4em .4em .8em;
    margin: 0 0 0 2.5em;
    background: var(--header-item-color);
    color: var(--header-nav-item-color);
    text-decoration: none;
    transition: all .3s ease-out;
}

.slide-list__content a:hover {
    background: color-mod(var(--header-item-color) blackness(+10%))
}

.slide-list__content a:before {
    content: counter(li);
    counter-increment: li;
    position: absolute;
    border-radius: 5px;
    left: -2.5em;
    top: 50%;
    margin-top: -1em;
    background: var(--header-item-color);
    color: var(--header-nav-item-color);
    height: 2em;
    width: 2em;
    line-height: 2em;
    text-align: center;
    font-weight: bold;
}

.slide-list__content a:after {
    position: absolute;
    content: "";
    border: .5em solid transparent;
    left: -1em;
    top: 50%;
    margin-top: -.5em;
    transition: all .3s ease-out;
}

.slide-list__content a:hover:after {
    left: -.5em;
    border-left-color: var(--header-item-color);
}

.slide-list__create-new {
    @media (max-width: 480px) {
        padding-top: 10px;
        text-align: center;
    }
}

.slide-list__button-delete {
    position: relative;
    text-decoration: none;
    border-radius: 200px;
    display: flex;
    align-items: center;
    background: var(--header-item-color);
    color: var(--header-bg-color);
    padding: 0px 20px;
}

.slide-list__button-create {
    float: right;
    padding: 10px 20px;
    align-items: center;
    text-decoration: none;
    border-radius: 200px;
    background: var(--header-item-color);
    color: var(--header-bg-color);
    
    @media (max-width: 480px) {
        float: none;
    }
}

.slide-list__button-delete:hover,.slide-list__button-create:hover {
    background: color-mod(var(--header-item-color) blackness(+10%));
    cursor: pointer;
}

/* slide customization */

.slide-customization {
    position: relative;
    min-height: inherit;
    padding: 20px 0;
    @media (max-width: 425px) {
        padding: 20px 0;
    }
}

.slide-customization__title {
    font-family: Roboto;
    font-weight: normal;
    font-style: normal;
    font-size: 34px;
    letter-spacing: 0.25px;
    color: var(--main-font-color);
    margin-top: 0;
}

.slide-customization__image-wrapper {
    width: 100%;
}

.slide-customization__image-wrapper img {
    width: 100%;
    background-image: url('data:image/gif;base64,R0lGODlhCgAIAIABAN3d3f///yH5BAEAAAEALAAAAAAKAAgAAAINjAOnyJv2oJOrVXrzKQA7');
}

.slide-customization__options {
    display: flex;
    align-items: center;
    padding: 30px 0;
}

.slide-customization__customizationer {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.slide-customization__checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.slide-customization__lever {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;

    &::before {
        position: absolute;
        border-radius: 50%;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    &:hover {
        background-color: color-mod(#ccc blackness(+10%));
    }
}

.slide-customization__checkbox:checked + .slide-customization__lever {
    background-color: var(--main-color);/*#2196F3 */
    
    &:hover{
        background-color: color-mod(var(--main-color) blackness(+10%));
    }
}

.slide-customization__checkbox:focus + .slide-customization__lever {
    box-shadow: 0 0 1px var(--main-color);/*#2196F3 */
}

.slide-customization__checkbox:checked + .slide-customization__lever::before {
    transform: translateX(26px);
}

.slide-customization__select {
    width: 49%;
    height: 35px;
    font-size: 1.2rem;
}

.slide-customization__input-field {
    position: relative;
    margin-top: 25px;
    padding: 15px 0 0;
    width: 50%;
}

.slide-customization__textarea {
    font-family: inherit;
    width: 100%;
    border: 0;
    border-bottom: 2px solid #9b9b9b;
    outline: 0;
    font-size: 1.3rem;
    padding: 7px 0;
}

.slide-customization__textarea::placeholder {
    color: transparent;
}

.slide-customization__textarea:placeholder-shown ~ .slide-customization__field-label {
    font-size: 1.3rem;
    cursor: text;
    top: 20px;
}

.slide-customization__field-label {
    position: absolute;
    top: -15px;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
}

.slide-customization__textarea:focus ~ .slide-customization__field-label {
    position: absolute;
    top: -15px;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    padding-bottom: 6px;
    font-weight: 400;
}

.slide-customization__action {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 0;
}

.slide-customization__button {
    position: relative;
    text-decoration: none;
    border-radius: 200px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: none;
    padding: 10px 20px;
    outline: none;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    background: var(--header-item-color);
    color: var(--header-bg-color);
}

.slide-customization__button:hover {
    background: color-mod(var(--header-item-color) blackness(+10%));
    cursor: pointer;
}


/* slider */
.swiper-slide {
    height: 100% !important;
}

.swiper-slide__block {
    display: flex;
    align-items: center;
}

.swiper-slide__img {
    height: 100% !important;
    width: 130% !important;
    border-radius: none !important;
    transform: translateX(10%);
}

.swiper-slide__description h3{
    font-weight: 700;
    font-size: 80px;
    line-height: 97px;
    text-transform: uppercase;
}

.swiper-slide__description p{
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    text-transform: lowercase;
}

.swiper-pagination-bullets {
    bottom: 30px;
}

.swiper-pagination-bullet {
    background-color: #F1F1F1 !important;
    opacity: 1;
    @media (min-width: 768px) {
        height: 16px;
        width: 16px;
    }
}
.swiper-pagination-bullet-active {
    background-color: #EF4618 !important;
}
